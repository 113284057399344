import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Comment, CommentStatus } from '~/domain/Comment/aggregate';
import { trpc, useUtils } from '~/utils/trpc';
export type CommentsConfig = {
  comments: Comment[];
  general?: boolean;
  sortUnresolved?: boolean;
  fetchNextPage?: () => void;
};

export type CommentsModel = {
  comments: Comment[];
  addComment: (comment: Comment) => void;
  setStatus: (status: CommentStatus, comment: Comment, index: number) => void;
  filterComments: (status: CommentStatus | '') => void;
  statusFilter: CommentStatus | '';
  statusCount: { resolved: number; unresolved: number };
  setComments: React.Dispatch<React.SetStateAction<Comment[]>>;
  general: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  setHasNextPage: (d: boolean | undefined) => void;
};

export const useComments = ({
  comments: initialComments,
  general,
  sortUnresolved,
  fetchNextPage,
}: CommentsConfig): CommentsModel => {
  const [comments, setComments] = useState(initialComments);
  const [statusFilter, setStatusFilter] = useState<CommentStatus | ''>('');
  const [filteredComments, setFilteredComments] = useState(initialComments);
  const [hasNextPage, setHasNextPage] = useState<undefined | boolean>();
  const toast = useToast();
  const utils = useUtils();

  const { mutateAsync: updateStatus } = trpc.comment.updateStatus.useMutation({
    onSuccess() {
      toast({
        title: 'Status Updated',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
      utils.comment.getAll.invalidate();
    },
  });

  const [statusCount, setStatusCount] = useState<{
    resolved: number;
    unresolved: number;
  }>({
    resolved: initialComments.filter(({ status }) => status === 'resolved')
      .length,
    unresolved: initialComments.filter(({ status }) => status === 'unresolved')
      .length,
  });

  const addComment = (comment: Comment) => {
    setComments([comment, ...comments]);
  };

  const setStatus = async (
    status: CommentStatus,
    comment: Comment,
    index: number,
  ) => {
    const copy = comments;
    copy.splice(index, 1, { ...comment, status });
    setComments(copy);
    setFilteredComments(copy);
    setStatusCount({
      resolved: copy.filter(({ status }) => status === 'resolved').length,
      unresolved: copy.filter(({ status }) => status === 'unresolved').length,
    });

    updateStatus({
      id: comment.id!,
      status,
    });
  };

  const filterComments = (status: CommentStatus | ''): void => {
    setStatusFilter(status);
    if (status === '') {
      setFilteredComments(comments);
      return;
    }
    setFilteredComments(
      comments.filter((comment) => {
        return status === comment.status;
      }),
    );
  };

  useEffect(() => {
    filterComments(statusFilter);
  }, [comments, statusCount.resolved, statusCount.unresolved]);

  useEffect(() => {
    setStatusCount({
      resolved: comments.filter(({ status }) => status === 'resolved').length,
      unresolved: comments.filter(({ status }) => status === 'unresolved')
        .length,
    });
    filterComments('');
  }, [comments.length]);

  const sortComments = (comments: Comment[]) => {
    const dateSorted = comments.sort((a, b) => b.id! - a.id!);
    if (sortUnresolved) {
      return dateSorted.sort((a, b) => {
        if (a.status! > b.status!) {
          return -1;
        }

        if (a.status! < b.status!) {
          return 1;
        }

        return 0;
      });
    }
    return dateSorted;
  };

  return {
    setStatus,
    addComment,
    comments: sortComments(filteredComments),
    filterComments,
    statusFilter,
    statusCount,
    setComments,
    general: !!general,
    setHasNextPage,
    hasNextPage,
    fetchNextPage,
  };
};

import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  IconButton,
  Select,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { GoReply } from 'react-icons/go';
import { Card } from '~/components/common';
import { Comment, CommentStatus } from '~/domain/Comment/aggregate';
import { getMonthDayTime } from '@tb/common';

const addAssigneeToComment = (assignee: string | undefined, text: string) => {
  if (assignee) {
    const name = assignee.split(' ')[0];
    return `@${name} ${text}`;
  }
  return text;
};

// const getLink = (comment: Comment) => {
//   const { lineItem } = comment;

//   if (lineItem) {
//     return {
//       pathname: `/projects/${lineItem?.project.id}/estimate`,
//       query: { dbId: lineItem.id },
//     };
//   }
//   return '';
// };

export const CommentItem = ({
  comment,
  index,
  setStatus,
  general,
}: {
  comment: Comment;
  index: number;
  setStatus: (status: CommentStatus, comment: Comment, index: number) => void;
  general?: boolean;
}) => {
  const { name, status, timeStamp, text, assignedUser, id, link } = comment;

  return (
    <Card key={id} gap="3" p={3}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap="2" alignItems="center">
          <Avatar size="xs" bg="brand.softLight" color="black" name={name} />
          <Heading size="sm">{name}</Heading>
        </Box>
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setStatus(e.target.value as CommentStatus, comment, index)
          }
          size="sm"
          w="105px"
          value={status}
          bg={status === 'resolved' ? 'brand.resolved' : 'brand.unresolved'}
          variant="comment-select"
        >
          <option value="unresolved">Unresolved</option>
          <option value="resolved">Resolved</option>
        </Select>
      </Box>
      <Box px={3}>
        <Text variant="body-xs" pb={3}>
          {addAssigneeToComment(assignedUser, text)}
        </Text>
        <Text variant="body-xs-muted">{getMonthDayTime(timeStamp)}</Text>
      </Box>
      {!!general && (
        <>
          {/* TODO: Add bidder link */}
          {!!link && (
            <>
              <Divider />

              <Flex justify={'space-between'} align="center">
                <Text noOfLines={1} variant="body-xs-muted">
                  {link.description}
                </Text>
                <NextLink
                  href={{ pathname: link.pathname, query: link.query }}
                  passHref
                  legacyBehavior
                >
                  <IconButton
                    as="a"
                    variant={'link'}
                    aria-label="Go to Page"
                    icon={<GoReply />}
                  />
                </NextLink>
              </Flex>
            </>
          )}
        </>
      )}
    </Card>
  );
};

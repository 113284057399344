import { Box, Flex, Heading, Link } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Project } from '@tb/common';
import { ExpandableCard } from '@tb/ui';
import { useSession } from 'next-auth/react';
import NextLink from 'next/link';
import { ReactElement, ReactNode, useMemo } from 'react';
import { HomePageComments } from '~/components/Comments/HomePageComments';
import DefaultLayout from '~/components/Layouts/DefaultLayout';
import { OverviewProjectsListView } from '~/components/Overview/OverviewProjectList';
import { CalendarView } from '~/components/Project/Calendar';
import { Card, PageSpinner } from '~/components/common';
import { defaultQueryOpts, trpc } from '~/utils/trpc';
import { NextPageWithLayout } from './_app';
export const OverviewPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  /* 72px is derived from the top search navbar */
  height: calc(100vh - 102px);
  margin: 0;
`;

const OverviewPageContentContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <Flex height="inherit" width="inherit" gap={6}>
      {children}
    </Flex>
  );
};

const ProjectsList = ({ projectData }: { projectData: Project[] }) => {
  return (
    <Flex flexDirection="column" flex={2} data-pendo-id="recent-projects">
      <Flex justifyContent={'flex-end'} align={'center'} pb={1}>
        <NextLink href="/projects" passHref legacyBehavior>
          <Link variant="overview-link">View All</Link>
        </NextLink>
      </Flex>
      <Box overflowY={'scroll'} h={'100%'}>
        <OverviewProjectsListView projects={projectData} />
      </Box>
    </Flex>
  );
};

const IndexPage: NextPageWithLayout = () => {
  const { status } = useSession();
  const { data: projectData } = trpc.project.getAll.useQuery(
    undefined,
    defaultQueryOpts(status, { skipBatch: true }),
  );

  const { data: calendarEvents } = trpc.bid.getEvents.useQuery(
    undefined,
    defaultQueryOpts(status),
  );

  const ProjectTable = useMemo(() => {
    if (projectData) {
      return <ProjectsList projectData={projectData} />;
    } else {
      return <PageSpinner />;
    }
  }, [projectData]);

  const ProjectCalendar = useMemo(() => {
    if (calendarEvents) {
      return <CalendarView events={calendarEvents} />;
    }
    return <PageSpinner />;
  }, [calendarEvents]);

  return (
    <OverviewPageContainer>
      <OverviewPageContentContainer>
        <Flex flexDirection="column" width="70%" overflow="auto" gap={6}>
          <Card height="40%" flexDirection={'column'}>
            <Heading as="h2" size="md">
              Recent Projects
            </Heading>
            <Flex w="100%" height="100%">
              {ProjectTable}
            </Flex>
          </Card>
          <Flex height="60%" width="100%" data-pendo-id="calendar">
            <ExpandableCard gap="3" w="100%">
              {ProjectCalendar}
            </ExpandableCard>
          </Flex>
        </Flex>
        <HomePageComments />
      </OverviewPageContentContainer>
    </OverviewPageContainer>
  );
};

IndexPage.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default IndexPage;

/**
 * If you want to statically render this page
 * - Export `appRouter` & `createContext` from [trpc].ts
 * - Make the `opts` object optional on `createContext()`
 *
 * @link https://trpc.io/docs/ssg
 */
// export const getStaticProps = async (
//   context: GetStaticPropsContext<{ filter: string }>,
// ) => {
//   const ssg = createSSGHelpers({
//     router: appRouter,
//     ctx: await createContext(),
//   });
//
//   await ssg.fetchQuery('post.all');
//
//   return {
//     props: {
//       trpcState: ssg.dehydrate(),
//       filter: context.params?.filter ?? 'all',
//     },
//     revalidate: 1,
//   };
// };

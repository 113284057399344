import { Heading, Select } from '@chakra-ui/react';
import { capitalize } from '@tb/common';
import { Card, DataWrapper } from '@tb/ui';
import { useSession } from 'next-auth/react';
import { useMemo, useState } from 'react';
import { Comment, CommentQuerySelect } from '~/domain/Comment/aggregate';
import { defaultQueryOpts, trpc } from '~/utils/trpc';
import CommentList from './CommentList';
import { useComments } from './useComments';

const Comments = ({
  comments,
  hasNextPage,
  fetchNextPage,
}: {
  comments: Comment[];
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
}) => {
  const commentsModel = useComments({
    comments: [],
    general: true,
    sortUnresolved: true,
    fetchNextPage,
  });
  // Needed to reset comments after filter change
  useMemo(() => {
    commentsModel.setHasNextPage(hasNextPage);
  }, [hasNextPage]);

  useMemo(() => {
    commentsModel.setComments(comments);
  }, [comments]);

  return <CommentList model={commentsModel} />;
};

interface Props {
  filter?: CommentQuerySelect;
}

const CommentWrapper = ({ filter }: Props) => {
  const { status } = useSession();
  const { data, isLoading, fetchNextPage, hasNextPage } =
    trpc.comment.getInfinite.useInfiniteQuery(
      {
        query: filter,
        limit: 5,
      },
      {
        ...defaultQueryOpts(status, { skipBatch: true }),
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor;
        },
      },
    );

  return (
    <DataWrapper
      queries={{
        data,
      }}
      isLoading={isLoading}
      fallback={{
        message: 'No comments found',
        info: 'Try changing filter values',
        image: null,
      }}
    >
      {({ data }) => {
        const comments = data.pages.reduce<Comment[]>((acc, cur) => {
          return [...acc, ...cur.comments];
        }, []);

        return (
          <Comments
            comments={comments || []}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
          />
        );
      }}
    </DataWrapper>
  );
};

type FilterOptions = 'all' | 'created' | 'mentioned' | 'assigned';

export const HomePageComments = () => {
  const [filter, setFilter] = useState<FilterOptions>('mentioned');
  const { data } = useSession();
  const email = data?.user?.email;

  const filterOptions: Record<FilterOptions, CommentQuerySelect | undefined> =
    useMemo(() => {
      const mentioned = email ? { mentioned: email } : undefined;

      return {
        all: undefined,
        created: {
          created: true,
        },
        assigned: {
          assigned: true,
        },
        mentioned,
      };
    }, [email]);

  const filterValue: CommentQuerySelect | undefined = filterOptions[filter];

  const options = Object.keys(filterOptions).map((key) => {
    return (
      <option value={key} key={key}>
        {capitalize(key)}
      </option>
    );
  });

  return (
    <Card flex={1} width="100%" height="100%" gap={4} data-pendo-id="comments">
      <Heading as="h2" size="md">
        Comments
      </Heading>
      <Select
        variant={'outline'}
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value as FilterOptions);
        }}
      >
        {options}
      </Select>
      <CommentWrapper filter={filterValue} />
    </Card>
  );
};

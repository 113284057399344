import styled from '@emotion/styled';
import { CalendarEvent } from '@tb/common';
import { DateTime, Settings } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { Calendar, Views, luxonLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const defaultTZ = DateTime.local().zoneName;

export const CalendarView = ({ events }: { events: CalendarEvent[] }) => {
  const color = (type: CalendarEvent['type']) => {
    switch (type) {
      case 'rfi':
        return '#498F8B';
      case 'expected':
        return '#C29DBB';
      case 'dueDate':
        return '#F6A2BD';
      case 'project':
        return '#4957b4';
      default:
        return '#4957b4';
    }
  };

  const eventPropGetter = useCallback(
    (
      event: CalendarEvent,
      _start: unknown,
      _end: unknown,
      _isSelected: unknown,
    ) => {
      return {
        style: {
          backgroundColor: color(event.type),
        },
      };
    },
    [],
  );

  const timezone = defaultTZ;

  const { defaultDate, getNow, localizer, myEvents, scrollToTime } =
    useMemo(() => {
      Settings.defaultZone = timezone;
      return {
        defaultDate: DateTime.local().toJSDate(),
        getNow: () => DateTime.local().toJSDate(),
        localizer: luxonLocalizer(DateTime),
        myEvents: events,
        scrollToTime: DateTime.local().toJSDate(),
      };
    }, [timezone]);

  useEffect(() => {
    return () => {
      Settings.defaultZone = defaultTZ; // reset to browser TZ on unmount
    };
  }, []);

  return (
    <StyledCalendar
      defaultDate={defaultDate}
      views={['month', 'week', 'day', 'agenda']}
      defaultView={Views.MONTH}
      events={myEvents}
      getNow={getNow}
      localizer={localizer}
      scrollToTime={scrollToTime}
      formats={{
        timeGutterFormat: 'HH:mm',
      }}
      eventPropGetter={eventPropGetter}
    />
  );
};

const StyledCalendar = styled(Calendar<CalendarEvent>)`
  .rbc-event {
    font-size: 12px;
    font-weight: 500;
    border-radius: 0;
  }
  .rbc-events-container {
    display: none;
  }
  .rbc-time-content {
    display: none;
  }
  .rbc-today {
    border-bottom: 1px solid rgb(221, 221, 221);
  }
  .rbc-time-header-content {
    height: 454px;
    border: none;
  }
  .rbc-allday-cell {
    height: 100%;
  }
  /*time stamp*/
  .rbc-label {
    display: none;
  }
  .rbc-date-cell {
    text-align: left;
    padding-left: 5px;
  }
  .rbc-button-link {
    font-size: 12px;
    font-weight: 500;
  }
  .rbc-header {
    padding: 5px 0;
    font-size: 12px;
    font-weight: 500;
  }
`;

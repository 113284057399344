import { Badge } from '@chakra-ui/react';
import { ProjectStage, capitalize } from '@tb/common';

type ProjectStageInput = { value: ProjectStage };

/**
 * Gets project stage display information
 * @example
 * projectStageFormat({value: 'Pending'})
 * @returns color and value
 */
export const projectStageFormat = (params: ProjectStageInput) => {
  const { color, name } = params.value;

  const string = capitalize(name);
  return {
    string,
    color,
  };
};

/**
 * Formats project stage badges
 * @example
 * projectStageBadge({value: 'Pending'})
 * @returns Project stage badge
 */
export const projectStageBadge = (
  params: ProjectStageInput,
  colorModeSaturation: string,
) => {
  const { color, string } = projectStageFormat(params);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Badge
      color={'#000'}
      backgroundColor={color}
      lineHeight={4}
      w="max-content"
      filter={colorModeSaturation}
    >
      {string}
    </Badge>
  );
};

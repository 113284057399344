import { Project } from '@tb/common';
import { useRouter } from 'next/router';
import { DataGrid, useDataGrid } from '~/components/common';
import { useProjectListColumns } from '../Project/ProjectListColumns';

export const OverviewProjectsList = ({ rowData }: { rowData: Project[] }) => {
  const router = useRouter();
  const { columnDefs } = useProjectListColumns({ overview: true });

  const dataGridModel = useDataGrid<Project>({
    rows: rowData,
    columns: columnDefs,
    onRowSelected: ({ data: row }) => {
      if (row) {
        router.push(`/projects/${row.id}`);
      }
    },
  });

  return <DataGrid model={dataGridModel} />;
};

export const OverviewProjectsListView = ({
  projects,
}: {
  projects: Project[];
}) => {
  return <OverviewProjectsList rowData={projects} />;
};

import { Center, Spinner } from '@chakra-ui/react';
import {
  Project,
  ProjectStage,
  getCurrencyFormatFromContext,
  getMonthDayYear,
} from '@tb/common';
import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ReactNode } from 'react';
import { useColorModeStuff } from '~/components/common';
import { projectStageBadge } from '~/utils/projectStageFormat';
import { trpc } from '../../utils/trpc';

type GetProjectListColumnsProps = {
  overview?: boolean;
};

export const useProjectListColumns = (props?: GetProjectListColumnsProps) => {
  const { overview = false } = props || {};
  const { colorModeSaturation5 } = useColorModeStuff();
  const columnDefs: ColDef<Project>[] = [
    { headerName: 'ID', field: 'code', width: 80 },
    { headerName: 'Name', field: 'name', tooltipField: 'name' },
    { headerName: 'Address', field: 'address', tooltipField: 'address' },
    { headerName: 'Estimate Owner', field: 'owner', tooltipField: 'owner' },
    {
      headerName: 'Assigned To',
      field: 'assignee',
      tooltipField: 'assignee',
    },
    {
      headerName: 'Stage',
      field: 'stage',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<Project, ProjectStage>) => {
        if (params.value) {
          return projectStageBadge(params, colorModeSaturation5);
        }
      },
    },
    {
      headerName: 'Deadline',
      field: 'deadline',
      hide: overview,
      cellRenderer: (
        params: ICellRendererParams<Project, Date | undefined>,
      ) => {
        if (params.value) {
          return getMonthDayYear(params.value);
        }
      },
    },
    {
      headerName: 'Status',
      hide: overview,
      field: 'status',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Total Cost',
      field: 'projectValue',
      suppressMenu: true,
      minWidth: 150,
      maxWidth: 175,
      type: 'numericColumn',
      cellRenderer: (
        params: ICellRendererParams<Project, 'projectValue'>,
      ): ReactNode => {
        const { data, isLoading } =
          trpc.estimates.getProcessedEstimate.useQuery(params.data?.id || '', {
            enabled: !!params.data && params.data.projectValue.amount <= 0,
            trpc: {
              context: {
                skipBatch: true,
              },
            },
          });

        if (params.data && params.data.projectValue.amount > 0) {
          return getCurrencyFormatFromContext(
            params.data.projectValue.amount,
            params.data.projectValue.currency,
          );
        }
        if (isLoading) {
          return (
            <Center>
              <Spinner size="sm" />
            </Center>
          );
        }
        return getCurrencyFormatFromContext(data?.total || 0, 'USD');
      },
    },
  ];

  return { columnDefs };
};

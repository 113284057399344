import { Box, Flex, Text } from '@chakra-ui/react';
import { LoadMoreButton } from '~/components/common';
import { CommentItem } from './CommentItem';
import { CommentsModel } from './useComments';

const CommentList = ({
  model,
}: {
  model: CommentsModel;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
}) => {
  const { comments, setStatus, general, hasNextPage, fetchNextPage } = model;

  return (
    <Flex flexDirection="column" overflowY="auto" h={'-webkit-fill-available'}>
      {comments.length === 0 ? (
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          height="100%"
          py={12}
        >
          <Text>No comments yet</Text>
        </Flex>
      ) : (
        comments.map((comment, index) => (
          <Box mb={1} mt={index === 0 ? 0 : 1} key={comment?.id || index}>
            <CommentItem
              general={general}
              comment={comment}
              index={index}
              setStatus={setStatus}
            />
          </Box>
        ))
      )}
      <LoadMoreButton hasNextPage={hasNextPage} fetchNextPage={fetchNextPage} />
    </Flex>
  );
};

export default CommentList;
